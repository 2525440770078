
body{
    overflow-x: hidden;


}

.cart-button {
    border: 1px #2B6CB0 solid;
    padding: 3vh 7.25vw;
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    color: #2B6CB0;
    display: flex;
}

.title-text {
    text-align: left;
}

.description-text {
    text-align: justify;
    min-height: 20vh;
    
}

.icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3vh;
}

.icons {
    margin: 1vh;
}