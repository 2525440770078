*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
}
.col-2{
    float: left;
    width: 50%;
    padding: 10px;
    height: 100px;
}
.row::after{
    content: "";
    display: table;
    clear: both;
}
h7{
    color: black;
    font-weight: bold;
    font-size: 25px;
    margin-left: -50px;
    
}
.price-col{
    color: rgb(19, 19, 116);
    font-size: 30px;
    font-weight: 600;
    margin-left: -50px;
}
button1{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: white;  
    background-position: 0%;
    transition: 0.4s;
    color: white;
    width: 30%;
    margin-left: -50px;
    margin-top: 10px;
  }
  span{
    margin-left: 30px;
    color: rgb(53, 53, 170);
    background-color: white;
    font-size: larger;
  }
  button2{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: rgb(53, 53, 170);
    background-position: 0%;
    transition: 0.4s;
    color: white;
    width: 45%;
    margin-top: 20px;
    text-align: center;
    margin-left: -50px;
  }
  button2 hover{
    background-color: white;
  }
  button3{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: white;
    background-position: 0%;
    transition: 0.4s;
    color: rgb(53, 53, 170) ;
    width: 45%;
    margin-top: 20px;
    margin-left: 30px;
    text-align: center;
    outline: 1px solid;
    outline-color: rgb(53, 53, 170) ;;
    
  }
  .product_details{
    margin-top: 50px;
  
    font-weight: bold;
    font-size: 23px;
    color: black;
    margin-left: -50px;
  }
  .desc-col{
    margin-left: 10px;
    font-weight: 500;
    color: rgb(122, 118, 118);
    margin-left: -50px;
  }
  .price-col sub{
    font-size: 15px;
    margin-left: 15px;
    color: rgb(122, 118, 118);;
  }
  div{
    color: rgb(122, 118, 118);;
    
  }
  .quantity-col{
    margin-left: -50px;
    font-weight: 600;
  }
  ul,ol{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-x: hidden;
  }
  li{
    
    float: left;
  }
  
  li a{
    display: block;
    margin-bottom: 2px;
    text-decoration: none;  
    margin-left: 50px;
    border: 1px solid transparent;
    
  }
  li a:hover{
    border: 1px solid blue;
  }
  @media only screen and (min-width : 360px) and (max-width : 950px) {
    .col-2{
      min-width:100%;
      margin-left: -40px;
      margin-top: -40px;
    }
    img{
      max-height: 40vh;
      border: 2px solid blue;
      width: 150%;
    }
li a{
  display: block;
  margin-left: 18px;
  padding: 0;
  height: 120px;
  width: 80px;
  margin-top: 10px;
  align-self: center;
}
li{
  float:left;

}
.enginecover {
  
  margin-top: 440px;
  margin-left: 100px;
}
.price-col{
  margin-left: 50px;
}
.quantity-col{
  margin-left: 50px;
  font-weight: bold;
  font-size: larger;
  margin-top: -10px;
}
button1{
  margin-left: 50px;
  padding: 4px 1px;
  text-align: center;
  width: 40%;
  
}
span{
  margin-left: 33px;
  font-size: 20px;
  
}
button2{
  margin-left: 50px;
  width: 40%;
}
button3{
  margin-left: 18px;
  width: 40%;
}
.product_details{
  margin-left: 50px;
}
.desc-col{
  margin-left: 50px;
}
  }